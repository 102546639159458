<template>
	<v-app>
	<v-main>
		<IndexPage/>
	</v-main>
	</v-app>
</template>
<script>
import IndexPage from './pages/IndexPage';	
export default {
	name: 'App',
	components: {
		IndexPage,
	},

	data: () => ({
		//	
	}),
	beforeCreate() {
		// let websocket = new this.$websocket();
		// websocket.init();
		
		this.$push.Permission.request(this.onGranted, this.onDenied);
		// this.$push.create("标题",{
		// 	body:"xxx",
		// 	requireInteraction:false,
		// 	timeout:10000
		// });
	},
	methods:{
		onGranted(){
			console.log("授权");
		},
		onDenied(){
			console.log("拒绝授权");
		}
	}
};
</script>
