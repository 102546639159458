import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import axios from "axios"
import {API_URL} from "@/env.js"
import Push from 'push.js'
import webSocket from '@/js/websocket'

Vue.config.productionTip = false

Vue.prototype.$axios = axios;
Vue.prototype.$API_URL = API_URL;
Vue.prototype.$push = Push;
Vue.prototype.$websocket = webSocket;

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
