import {API_URL} from "@/env.js";
import axios from "axios"
export const list = {
	getDatas(data){
		return new Promise((resolve, reject) => {
			axios.get(API_URL+"index/list?" + new URLSearchParams(data).toString()).then((res) => {
				resolve(res);
			}).catch((e) => {
				reject(e);
			})
		
		});
	},
	saveBalance(data){
		return new Promise((resolve, reject) => {
			axios.post(API_URL+"index/balance", data).then(function (res) {
				resolve(res);
			}).catch(function (e) {
				reject(e);
			});
		});
	}
};

export default {
	list
}