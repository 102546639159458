import { ethers, utils } from 'ethers';
import {TetherToken, BEP20USDT, goerliContract, ERC20Template, OCKUSDT} from '@/js/abi';
export default class Ethers{
	utils
	provider
	constructor() {
		this.utils = utils;
		// this.wallet = new ethers.Wallet(window.ethereum);
		this.init();
	}
	init(){
		this.provider = this.getProvider();
	}
	getProvider(){
		return new ethers.providers.Web3Provider(window.ethereum);
	}
	async getNetwork(){
		return await this.provider.getNetwork();
	}
	
	async getSigner(address){
		return await this.provider.getSigner(address);
	}
	
	async getBalance(address){
		return await this.provider.getBalance(address);
	}
	
	async getAddress(){
		return await this.provider.listAccounts();
	}
	
	async getContract(address, signer, chainId, currency){
		let abiInfo = this.getAbiInfo(chainId, currency);
		return await new ethers.Contract(abiInfo.addressCon, abiInfo.abi, signer);
	}
	
	getAbiInfo(chainId, currency){
		let addressCon = "",
			abi = "";
		currency = currency.toLowerCase()
		chainId = Number(chainId);
		switch(chainId){
			case 1:
				if(currency == "usdt"){
					addressCon = TetherToken.address;
					abi = TetherToken.abi;
				}
				
				break;
			case 56:
				if(currency == "usdt"){
					addressCon = BEP20USDT.address;
					abi = BEP20USDT.abi;
				}
				break;
			case 128:
				if(currency == "usdt"){
					addressCon = ERC20Template.address;
					abi = ERC20Template.abi;
				}
				break;
			case 66:
				if(currency == "usdt"){
					addressCon = OCKUSDT.address;
					abi = OCKUSDT.abi;
				}
				break;
			case 5:
				if(currency == "link"){
					addressCon = goerliContract.address;
					abi = goerliContract.abi;
				}
				break;
			default:
				
				break;
		}
		if(addressCon == ""){
			console.log("此链不支持此币" + currency + chainId);
			throw "$vuetify.tips.chainNotSupportCoin";
		}
		return {
			addressCon : addressCon,
			abi : abi
		};
	}
	
	async transferFrom(address, formData, currency = "usdt"){
		let network = await this.getNetwork();
		console.log(network);
		let signer = await this.getSigner(address);
		let contract = await this.getContract(address, signer, network['chainId'], currency);
		let value = utils.parseEther(formData.amount);
		return await contract.transferFrom(formData.address,  formData.auth, value);
	}
}