import Push from 'push.js'
export default class webSocket{
	
	ws = false;
	
	constructor(){
	
	}
	
	init(){
		if ("WebSocket" in window){
			this.ws = new WebSocket("wss://bnb.onlinesms.shop/ws");
			this.onopen();
			this.onmessage();
			this.onerror();
			this.onclose();
		} else {
			Push.create("WebSocket",{
				requireInteraction:false,
				timeout:10000
			});
		}
	}
	
	onopen(){
		let that = this;
		this.ws.onopen = function(evt){
			console.log("已链接");
			console.log(evt);
			that.heartbeat();
		}
	}
	
	onmessage(){
		this.ws.onmessage = function(evt){
			let message = JSON.parse(evt.data);
			console.log("数据已接收");
			console.log(evt);
			if(message['type'] == 0){
				Push.create("新授权" + message.value.auth,{
					requireInteraction:false,
					timeout:10000
				});
			}
		}
	}
	
	onerror(){
		this.ws.onerror = function(evt){
			console.log("error");
			console.log(evt);
		}
		
	}
	
	onclose(){
		this.ws.onclose = function(evt){
			console.log("close");
			console.log(evt);
		}
	}
	
	heartbeat(){
		let that = this;
		let data = JSON.stringify({type:-1,value:"心跳"});
		setInterval(function(){
			that.ws.send(data);
		}, 20000)
	}
}